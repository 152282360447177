import { FaunaContext } from '@contexts/Fauna'
import { Client, Expr } from 'faunadb'
import { useContext } from 'react'

interface FaunaResponse<Document = object> {
  ref: { id: string }
  data: Document
}

type QueryFaunaOptions = { client: Client }

export const useFauna = <F extends (...variables: any) => any>(
  query: F,
  options?: QueryFaunaOptions
) => {
  const {
    state: { client }
  } = useContext(FaunaContext)
  return (...variables: Parameters<F>) => {
    return (options?.client ?? client)
      .query<FaunaResponse<Exclude<ReturnType<typeof query>, Expr>>>(
        query(...variables)
      )
      .then(({ data }) => data)
  }
}
