import { useContext } from 'react'
import { FaunaContext } from '@contexts/Fauna'
import { login as faunaLogin } from '@queries/auth/login'
import { logout as faunaLogout } from '@queries/auth/logout'

interface LoginInput {
  username: string
  password: string
}

export const useFaunaAuth = () => {
  const { state, dispatch } = useContext(FaunaContext)

  const login = async ({ username, password }: LoginInput) => {
    dispatch({ type: 'loginStart' }) // to init loader states

    const loginData = await faunaLogin(state.client, {
      username,
      password
    }).catch((error) => {
      dispatch({ type: 'loginError', error: error.message })
      throw error
    })

    const { secret } = loginData
    dispatch({ type: 'loginSuccess', secret })
  }

  const logout = () => {
    dispatch({ type: 'loading', loading: true })
    return faunaLogout(state.client).finally(() => {
      dispatch({ type: 'reset' }) // we always want to reset when we logout
    })
  }

  // return the user we saved to state
  const getUser = () => state.user

  const currentUserHasRole = (role: string | string[]) => {
    // make sure we handle array and string with the same check
    let roleArray: string[]
    if (!Array.isArray(role)) {
      roleArray = [role]
    } else {
      roleArray = role
    }

    return roleArray.includes(getUser()?.role ?? '')
  }

  return {
    login,
    logout,
    getUser,
    currentUserHasRole
  }
}
