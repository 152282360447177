import React, { lazy, Suspense } from 'react'
import { Switch, Route } from 'react-router-dom'

// Route components
const ReceiptChecklist = lazy(() => import('./pages/ReceiptChecklist'))
const Shipment = lazy(() => import('./pages/Shipment'))
const Shipments = lazy(() => import('./pages/Shipments'))
const ShipmentsNew = lazy(() => import('./pages/ShipmentsNew'))
const Supplies = lazy(() => import('./pages/Supplies'))
const CustomerOrder = lazy(() => import('./pages/CustomerOrder'))
const ProductNew = lazy(() => import('./pages/ProductNew'))
const Products = lazy(() => import('./pages/Products'))
const CustomerNew = lazy(() => import('./pages/CustomerNew'))
const Customers = lazy(() => import('./pages/Customers'))
const Home = lazy(() => import('./pages/Home'))

const Routes = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <Switch>
      <Route path="/receipts/:id/checklist" component={ReceiptChecklist} />
      <Route path="/shipments/new" component={ShipmentsNew} />
      <Route path="/shipment/:id" component={Shipment} />
      <Route path="/shipments" component={Shipments} />
      <Route path="/Supplies" component={Supplies} />
      <Route path="/product/new" component={ProductNew} />
      <Route path="/products" component={Products} />
      <Route path="/customer/:id/order" component={CustomerOrder} />
      <Route path="/customer/new" component={CustomerNew} />
      <Route path="/customers" component={Customers} />
      <Route path="/" component={Home} />
    </Switch>
  </Suspense>
)

export default Routes
