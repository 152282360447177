import React, {
  createContext,
  Dispatch,
  FC,
  useEffect,
  useReducer
} from 'react'
import { Client, query as queryFunctions } from 'faunadb'
import Cookies from 'js-cookie'
import { useFauna } from '@hooks/useFauna'
import { useQuery } from 'react-query'
import { QueryResponseWrapper } from 'types'

export interface UserDocument {
  id?: string
  email?: string
  name?: string
  role?: 'user' | 'manager' | 'minion' | 'sales'
  hasAuth: boolean
}

interface FaunaContextState {
  user: UserDocument
  client: Client
  loading: boolean
  error: string
}

type ReducerAction = {
  type:
    | 'loginStart'
    | 'loginError'
    | 'loginSuccess'
    | 'loading'
    | 'reset'
    | 'user'
  [key: string]: any
}

interface FaunaContextValue {
  state: FaunaContextState
  dispatch: Dispatch<ReducerAction>
}

const { REACT_APP_FAUNA_CLIENT_KEY = '' } = process.env
const secret = Cookies.get('sales_auth_cookie')

const initialState: FaunaContextState = {
  user: { hasAuth: !!secret },
  client: new Client({
    secret: secret ?? REACT_APP_FAUNA_CLIENT_KEY
  }), // we try to use a user client if we can
  loading: true,
  error: ''
}

const reducer = (
  state: FaunaContextState,
  action: ReducerAction
): FaunaContextState => {
  switch (action.type) {
    case 'loginStart': {
      return { ...state, loading: true }
    }
    case 'loginSuccess': {
      const { secret } = action
      const client = new Client({ secret }) // create a new client so we can act as user
      Cookies.set('sales_auth_cookie', secret, { expires: 7 }) // set login cookie to persist login
      return { ...state, client, user: { hasAuth: true } } // we set role user the user will be promoted to his role later
    }
    case 'loginError': {
      return {
        ...state,
        loading: false,
        error: action.error
      }
    }
    case 'user': {
      return { ...state, user: action.user, loading: false }
    }
    case 'loading': {
      return { ...state, loading: action.loading }
    }
    case 'reset': {
      Cookies.remove('sales_auth_cookie') // remove secret cookie so we don't try and use this secret again
      const client = new Client({ secret: REACT_APP_FAUNA_CLIENT_KEY }) // create a new client so we can act as user
      return {
        ...state,
        client,
        user: { hasAuth: false },
        loading: false
      }
    }
    default: {
      return state
    }
  }
}

export const FaunaContext = createContext<FaunaContextValue>({
  state: initialState,
  dispatch: () => null
})

// TODO: Move this to it's own query
const { Get, CurrentIdentity, Let, Var, Select } = queryFunctions
const getUserQuery = (): QueryResponseWrapper<Omit<UserDocument, 'hasAuth'>> =>
  Let(
    {
      user: Get(CurrentIdentity())
    },
    {
      data: {
        id: Select(['ref', 'id'], Var('user')),
        email: Select(['data', 'email'], Var('user')),
        name: Select(['data', 'name'], Var('user')),
        role: Select(['data', 'role'], Var('user'))
      }
    }
  )

export const FaunaContextProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const enabled = !!state.client && state.user.hasAuth
  const request = useFauna(getUserQuery, {
    client: state.client
  })
  const { data: user, remove } = useQuery('getAuthUser', () => request(), {
    refetchInterval: 5000,
    enabled,
    notifyOnChangeProps: ['data', 'error'],
    onError: () => {
      console.log('Got auth error resetting user')
      dispatch({ type: 'reset' })
    }
  })

  // if we go a valid user we update teh fauna context to include the new user
  useEffect(() => {
    if (user) {
      dispatch({ type: 'user', user: { ...user, hasAuth: true } })
    }
  }, [user, dispatch, remove])

  // we make sure to clear the cache on logout to prevent issues if user tries to log back in
  useEffect(() => {
    if (!state.user.hasAuth) {
      remove()
    }
  }, [state.user.hasAuth, remove])

  const contextValue = { state, dispatch }
  return (
    <FaunaContext.Provider value={contextValue}>
      {children}
    </FaunaContext.Provider>
  )
}
