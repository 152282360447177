import React, { createContext, FC, useState } from 'react'
import rosetta from 'rosetta'
import { dictionary as en } from './dictionaries/en'
import { dictionary as fr } from './dictionaries/fr'
import Cookies from 'js-cookie'

export type Locale = 'en' | 'fr'

interface LocaleContextValue {
  setLocale: (locale: Locale) => Locale
  getLocale: () => Locale
  t: typeof i18n.t
}

const i18n = rosetta({
  en,
  fr,
})

// set default language
i18n.locale(Cookies.get('selected_locale') ?? 'en')

export const LocaleContext = createContext<LocaleContextValue>({
  setLocale: (locale: Locale) => i18n.locale(locale) as Locale,
  getLocale: () => i18n.locale() as Locale,
  t: (...args) => i18n.t(...args),
})

export const LocaleContextProvider: FC = ({ children }) => {
  const [, setTick] = useState(0)
  const contextValue: LocaleContextValue = {
    setLocale: (locale: Locale) => {
      // set a cookie to remember selected locale, expiry is required for cookies we set it 10 years in future
      Cookies.set('selected_locale', locale, { expires: 315360000 })
      // to trigger a re-render of site in the new locale
      setTick((tick) => tick + 1)
      return i18n.locale(locale) as Locale
    },
    getLocale: () => i18n.locale() as Locale,
    t: (...args) => i18n.t(...args), // we don't want to bind the rosetta function state
  }
  return (
    <LocaleContext.Provider value={contextValue}>
      {children}
    </LocaleContext.Provider>
  )
}
