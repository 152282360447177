export const dictionary = {
  // layout starts
  menu: {
    dashboard: 'Dashboard',
    newShipments: 'New Shipments',
    supplies: 'Supplies',
    shipments: 'Shipments',
    customers: 'Customers',
    newCustomers: 'New Customers',
    products: 'Products',
    newProducts: 'New Products'
  },
  headerMenu: {
    logout: 'Log out'
  },
  // layout ends

  // pages start
  dashboard: {
    title: 'Dashboard'
  },
  customerOrder: {
    title: 'Order for: {{name}}',
    searchPlaceholder: 'Search order'
  },
  customers: {
    title: 'Customers',
    searchPlaceholder: 'Search customer',
    buttonOrderTooltip: 'Open order for week {{currentWeek}}',
    buttonOrder: 'Order',
    buttonEdit: 'Edit customer',
    editSuccess: 'Successfully edited {{name}}',
    deleteSuccess: 'Successfully deleted {{name}}',
    deleteConfirmation: 'Are you sure to delete {{name}}?',
    deleteOk: 'Yes',
    deleteCancel: 'No'
  },
  login: {
    title: 'Log in',
    username: 'Username',
    usernamePlaceholder: 'Username',
    usernameRequired: 'Please input your Username.',
    password: 'Password',
    passwordPlaceholder: 'Password',
    passwordRequired: 'Please input your Password.',
    submitButton: 'Log in'
  },
  products: {
    title: 'Products',
    searchPlaceholder: 'Search product',
    buttonEditTooltip: 'Edit product',
    editSuccess: 'Successfully edited {{name}}',
    deleteConfirmation: 'Are you sure to delete {{name}}?',
    deleteOk: 'Yes',
    deleteCancel: 'No'
  },
  supplies: {
    title: 'Supplies',
    searchPlaceholder: 'Search supplies'
  },
  shipments: {
    title: 'Shipments',
    searchPlaceholder: 'Search shipment'
  },
  // pages end

  // components start
  customerForm: {
    name: 'Name',
    namePlaceholder: 'Customer name',
    nameRequired: 'Name is required',
    address: 'Address',
    addressPlaceholder: 'Address',
    addressRequired: 'Address is required',
    phonenumber: 'Phonenumber',
    phonenumberPlaceholder: 'Phonenumber',
    phonenumberRequired: 'Phonenumber is required',
    ssn: 'SSN',
    ssnPlaceholder: 'ssn',
    ssnRequired: 'SSN is required',
    discount: 'Discount',
    discountPlaceholder: 'Discount in %',
    discountRequired: 'Discount is required',
    discountNumber: 'Discount must be a number',
    notes: 'Notes',
    notesPlaceholder: 'Notes',
    inactive: 'Inactive'
  },
  makeForm: {
    submit: 'Submit'
  },
  orderLineForm: {
    stockLabelFront: 'In stock ',
    stockLabelConnecting: ' of ',
    price: 'Price ',
    priceTooltip:
      'Recommended price: €{{recommendedPrice}}, minimum price: €{{minPrice}}',
    priceMinimum: 'Min €{{price}}',
    quantity: 'Quantity',
    quantityMaximum: 'Available: {{quantity}}',
    quantityMinimum: 'Above 0'
  },
  productForm: {
    name: 'Name',
    namePlaceholder: 'Product name',
    nameRequired: 'Name is required',
    frenchName: 'French name',
    frenchNamePlaceholder: 'Product french name',
    frenchNameRequired: 'French name is required',
    productId: 'Product ID',
    productIdPlaceholder: 'Product unique identifier',
    productIdRequired: 'Barcode is required',
    minPrice: 'Min price',
    minPricePlaceholder: 'Min price',
    minPriceRequired: 'Min price is required',
    recommendedPrice: 'Recommended price',
    recommendedPriceRequired: 'Recommended price is required',
    recommendedPricePlaceholder: 'Recommended price'
  },
  shipmentsForm: {
    departureDate: 'Departure date',
    departureDateRequired: 'Departure date is required',
    departureDatePlaceholder: 'Select Departure date',
    shippingNumber: 'Shipping number',
    shippingNumberPlaceholder: 'Shipping number',
    shippingNumberRequired: 'Shipping number is requires',
    product: 'Product',
    productPlaceholder: 'Select product',
    productEditButton: 'Edit',
    productEditSuccess: 'Successfully edited "{{name}}"',
    productNewButton: 'New',
    productNewButtonTooltip: 'Create new product',
    productNewSuccess: 'Successfully creasted "{{name}}"',
    quantity: 'Quantity',
    quantityRequired: 'Quantity is required',
    addProduct: 'Add entry',
    submit: 'Submit'
  },
  shipmentListItem: {
    total: 'Total stock ',
    price: 'Price: €{{price}} ',
    priceTooltip: 'Minimum price: €{{price}}',
    deleteConfirmation: 'Are you sure you want to delete {{name}}?',
    deleteOk: 'Yes',
    deleteCancel: 'No'
  }
  // components end
}
