import React, { lazy, Suspense } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { ReactQueryDevtools } from 'react-query/devtools'
import { useFaunaAuth } from './hooks/useFaunaAuth'
import { FaunaContextProvider } from '@contexts/Fauna'
import { LocaleContextProvider } from '@contexts/Locale'
import { Main } from './layout/Main/Main'
import Routes from './Routes'
import loginStyles from './pages/Login/Login.module.css'
import { QueryClient, QueryClientProvider } from 'react-query'

const Login = lazy(() => import('./pages/Login'))
const queryClient = new QueryClient()

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <LocaleContextProvider>
        <FaunaContextProvider>
          <Router>
            <Authentication />
          </Router>
        </FaunaContextProvider>
      </LocaleContextProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

const Authentication = () => {
  const { getUser } = useFaunaAuth()
  const user = getUser()
  return user?.id ? (
    <Main>
      <Routes />
    </Main>
  ) : (
    <Suspense fallback={<div className={loginStyles.container}></div>}>
      <Login />
    </Suspense>
  )
}

export default App
