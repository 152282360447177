import React, { FC, useState } from 'react'
import { Button, Layout, Menu } from 'antd'
import Icon, {
  PieChartOutlined,
  TeamOutlined,
  TagsOutlined,
  ScheduleOutlined,
} from '@ant-design/icons'
import { GiCargoShip } from 'react-icons/gi'
import { useHistory, useLocation } from 'react-router-dom'
import styles from './Main.module.css'
import { useFaunaAuth } from '../../hooks/useFaunaAuth'
import { useLocale } from '@hooks/useLocale'

const { Header, Content, Footer, Sider } = Layout
const { SubMenu } = Menu

interface MainProps {}

export const Main: FC<MainProps> = ({ children }) => {
  const { t } = useLocale()
  const [collapsed, setCollapsed] = useState(false)
  const history = useHistory()
  const { pathname } = useLocation()
  const { currentUserHasRole } = useFaunaAuth()
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={() => {
          setCollapsed(!collapsed)
        }}
        theme="light"
      >
        <div className={styles.logoContainer}>
          <img src="/premium.png" alt="logo" className={styles.logo} />
        </div>
        <Menu theme="light" defaultSelectedKeys={[pathname]} mode="inline">
          <Menu.Item
            key="/"
            icon={<PieChartOutlined />}
            onClick={() => {
              history.push('/')
            }}
          >
            {t('menu.dashboard')}
          </Menu.Item>

          {currentUserHasRole(['manager', 'admin', 'sales']) && (
            <Menu.Item
              key="/supplies"
              icon={<ScheduleOutlined />}
              onClick={() => {
                history.push('/supplies')
              }}
            >
              {t('menu.supplies')}
            </Menu.Item>
          )}

          {currentUserHasRole(['manager', 'admin', 'minion']) && (
            <SubMenu
              key="sub"
              icon={<Icon component={GiCargoShip} />}
              title={t('menu.shipments')}
            >
              <Menu.Item
                key="/shipments"
                onClick={() => {
                  history.push('/shipments')
                }}
              >
                {t('menu.shipments')}
              </Menu.Item>

              <Menu.Item
                key="/shipments/new"
                onClick={() => {
                  history.push('/shipments/new')
                }}
              >
                {t('menu.newShipments')}
              </Menu.Item>
            </SubMenu>
          )}

          {currentUserHasRole(['manager', 'admin', 'sales']) && (
            <SubMenu
              key="sub1"
              icon={<TeamOutlined />}
              title={t('menu.customers')}
            >
              <Menu.Item
                key="/customers"
                onClick={() => {
                  history.push('/customers')
                }}
              >
                {t('menu.customers')}
              </Menu.Item>

              {currentUserHasRole(['manager', 'admin']) && (
                <Menu.Item
                  key="/customer/new"
                  onClick={() => {
                    history.push('/customer/new')
                  }}
                >
                  {t('menu.newCustomers')}
                </Menu.Item>
              )}
            </SubMenu>
          )}
          {currentUserHasRole(['manager', 'admin']) && (
            <SubMenu
              key="sub2"
              icon={<TagsOutlined />}
              title={t('menu.products')}
            >
              <Menu.Item
                key="/products"
                onClick={() => {
                  history.push('/products')
                }}
              >
                {t('menu.products')}
              </Menu.Item>

              <Menu.Item
                key="/product/new"
                onClick={() => {
                  history.push('/product/new')
                }}
              >
                {t('menu.newProducts')}
              </Menu.Item>
            </SubMenu>
          )}
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header className="text-right">
          <HeaderMenu />
        </Header>
        <Content style={{ margin: '16px 16px' }}>
          <div className={styles.content}>{children}</div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>Premium Iceland ©2020</Footer>
      </Layout>
    </Layout>
  )
}

export default Main

const HeaderMenu = () => {
  const { getLocale, setLocale, t } = useLocale()
  const { getUser, logout } = useFaunaAuth()
  const user = getUser()
  const currentLocal = getLocale()

  return (
    <>
      <span className="text-white mr-6">{user?.name}</span>
      <Button
        ghost
        onClick={() => {
          setLocale(currentLocal === 'en' ? 'fr' : 'en')
        }}
        className="mr-3"
      >
        {currentLocal === 'en' ? 'FR' : 'EN'}
      </Button>
      <Button onClick={logout} ghost>
        {t('headerMenu.logout')}
      </Button>
    </>
  )
}
