import { Client, query } from "faunadb"

interface LoginInput {
  username: string
  password: string
}

interface LoginResponse {
  ref: {
    '@ref': {
      id: string
      collection: { '@ref': { id: string } }
    }
  }
  ts: number
  instance: {
    '@ref': {
      id: string
      collection: {
        '@ref': { id: 'Users'; collection: { '@ref': { id: 'collections' } } }
      }
    }
  }
  secret: string
}

const { Login, Match, Index } = query

export const login = (client: Client, {
  username,
  password
}: LoginInput): Promise<LoginResponse> =>
  client.query(Login(Match(Index('users_by_email'), username), { password }))
